import React from "react"

const Skills = () => {
  return (
    <div>
      <div className="skills-heading">
        <div>
          <h2>Skills</h2>
        </div>
      </div>
      <div className="skills-container">
        <div className="skills-col">
          <h4>Frontend</h4>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>React</li>
            <li>Gatsby</li>
          </ul>
        </div>
        <div className="skills-col">
          <h4>Design</h4>
          <ul>
            <li>Adobe Photoshop CC</li>
            <li>Adobe Illustrator CC</li>
            <li>Adobe After Effects CC</li>
          </ul>
        </div>
        <div className="skills-col">
          <h4>CMS</h4>
          <ul>
            <li>Contentful</li>
            <li>Optimizely</li>
            <li>Airtable</li>
            <li>WordPress</li>
          </ul>
        </div>
        <div className="skills-col">
          <h4>Tools</h4>
          <ul>
            <li>VS Code</li>
            <li>GitHub</li>
            <li>GraphQL</li>
            <li>Netlify</li>
            <li>Node.js</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Skills
