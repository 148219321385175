import React from "react"
import Layout from "../components/Layout"
import Skills from "../components/Skills"
import AllProjects from "../components/AllProjects"
import ClientFeedback from "../components/ClientFeedback"
import SEOmeta from "../components/SEOmeta"

const IndexPage = ()=> {

return (
  <Layout>
    <SEOmeta title="Home" />
    <main className="page">
      <header className="hero">
        <div className="hero-container">
          <div className="hero-text">
            <h1 className="drop-bounce-text">
              <span>Shaun Vine</span>
            </h1>
            <h2>Creative Technologist</h2>
            <p>Creative Thinker and Technical Developer</p>
          </div>
        </div>
      </header>
      <Skills />
      <ClientFeedback />
      <AllProjects />
    </main>
  </Layout>
)
} 
  

export default IndexPage
